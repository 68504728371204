import * as React from 'react';

export function LoadAuth() {
    return (
    <>
        <script src="https://accounts.google.com/gsi/client" async defer></script>
        <div id="g_id_onload"
            data-client_id="531729770138-9q7bv3u3899il3qukg21qc01khejnu66.apps.googleusercontent.com"
            data-login_uri="budget-app.onrender.com/login" // TODO: Fix me
           >
        </div>
    </>
    )
}