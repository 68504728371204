import React from 'react';
import './App.css';
import {LoadAuth} from './auth/google-one-tap';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        Hello, World
      </header>
      <LoadAuth />
    </div>
  );
}

export default App;
